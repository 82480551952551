import PageSubHeading from "./PageSubHeading";

function EventProgram({ image, date, heading, greenRectangle, heading1, explanation, class1 }) {
  return (
    <div className='flex-cols justify-center'>
      <PageSubHeading title={heading} page="eventProgram" isShowArrow={false} />
      <div className='pt-8 relative w-full h-full flex flex-col'>
        <div className="relative">
          <img src={image} alt="Program" className="w-full flex-shrink-0" />
          <div className="absolute rounded-tr-8xl bottom-0 left-0 bg-custom-green text-black font-Inter p-2 text-[12px] sm:text-[16px] md:text-[22px] lg:text-[26px]">
            <div className='p-2 pl-4 pr-6 text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>{date}</div>
          </div>
        </div>
        <div className='pt-6 relative'>
          <h1 className={`font-Inter text-[14px] sm:text-[18px] lg:text-[20px] xl:text-[22px] font-bold relative z-10`}>
            {heading1}
          </h1>
          <p className={`pt-6 font-inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px] relative z-10`}>
            {explanation}
          </p>
        </div>
      </div>
    </div>
  )
}

export default EventProgram;
