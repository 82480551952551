import React, { useRef, useEffect, useState } from 'react';
import arrow from '../assets/arrow.png';
import greenRectangle from '../assets/Rectangle19.png';

export default function PageSubHeading({ title, page, isShowArrow, className = '' }) {
  const titleRef = useRef(null);
  const [titleWidth, setTitleWidth] = useState(0);
  const headingRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      setTitleWidth(titleRef.current.offsetWidth);
    }
  }, [title]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          headingRef.current.classList.add('animate-slideInLeft');
        }
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);

  return (
    <div className='flex flex-row items-start mt-2' ref={headingRef}>
      <div className='relative'>
        <div className={`relative ${page !== "eventProgram" ? "pl-6" : ""} ${page === "events" ? "lg:pl-14" : (page === "eventProgram" ? "" : "lg:pl-32")}`}>
          <h1
            ref={titleRef}
            className={`relative z-10 font-Inter text-[14px] sm:text-[22px] md:text-[27px] lg:text-[32px] font-bold mb-6 inline-block ${className}`}
            style={{ whiteSpace: 'nowrap' }}
          >
            {title}
          </h1>
          <div
            className='absolute  top-[45%] md:top-[60%] transform -translate-y-1/2 h-2 md:h-4'
            style={{
              backgroundImage: `url(${greenRectangle})`,
              backgroundSize: 'cover',
              width: `${titleWidth + 16}px`,
            }}
          ></div>
        </div>
      </div>
      {
        isShowArrow !== false &&
        <div className='relative mt-4 lg:mt-6 pl-8 sm:pl-10 lg:pl-10'>
          <img src={arrow} alt="Arrow" className="w-5 sm:w-6 lg:w-8" />
        </div>
      }
    </div>
  );
}
