import backgroundImage from '../assets/Banners1.png';
import Mo from '../assets/mo_final.png';
import Archie from '../assets/Archie.png';
import whiteRectangle from '../assets/Rectangle7.png';
import greenRectangle from '../assets/Rectangle19.png';
import yellowRectangle from '../assets/Rectangle15.png';
import Testimonial from '../components/Testimonial';
import Contact from '../pages/Contact';
import PageSubHeading from '../components/PageSubHeading';

function AboutUs() {
  return (
    <div className='bg-bg-grey'>
      <div className='relative pb-8'>
        <img
          src={backgroundImage}
          alt="Background"
          className='w-full rounded-br-[100px] md:rounded-br-[200px]'
        />
        <p className='absolute bottom-16 md:bottom-28 pl-10 lg:pl-20 pr-10 text-black font-inter ttext-[16px] sm:text-[28px] md:text-[28px] lg:text-[32px] xl:text-[40px] font-bold  text-shadow-xl'>
          Are you an entrepreneur interested in joining our program?
        </p>
      </div>

      <div>
        <div className='relative mt-4'>
          <PageSubHeading title="Our Mission" page="event" isShowArrow={false} />
        </div>
        <p className='text-left pt-4 pb-8 pl-10 lg:pl-32 pr-10 lg:pr-32 text-black font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>
          Our mission is to empower and uplift entrepreneurs from diverse and under-represented backgrounds, equipping them with the skills, resources, and community needed to build businesses that drive meaningful social impact.
          <br />
          <br />
          At Opulence, we believe in fostering an environment where visionary individuals—those who challenge the status quo and dare to dream big—can thrive. We are committed to supporting not just the growth of the business, but also the personal journey of each entrepreneur, nurturing their potential to lead with purpose and create ventures that contribute to a more inclusive, equitable, and sustainable society.
        </p>

        <div className='relative'>
          <PageSubHeading title="Our Vision" page="event" isShowArrow={false} />
        </div>
        <p className='text-left pb-8 pt-4 pl-10 lg:pl-32 pr-10 lg:pr-32 text-black font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>
          Our vision is a world where entrepreneurship is accessible to everyone!
          <br></br><br />
          A space where ideas can thrive, and economic empowerment is achieved through socially responsible businesses. To bring this vision to life, we've developed a robust program that supports entrepreneurs from all backgrounds and business areas. We aim not only for their business success but also for a broader positive impact on society. We believe that by empowering diverse entrepreneurs, we can drive sustainable, long-term change that benefits both our communities and society as a whole
        </p>

        <div className='relative'>
          <PageSubHeading title="Meet the Team" page="event" isShowArrow={false} />
        </div>
        <p className='text-left pt-6 pl-10 lg:pl-32 pr-10 lg:pr-32 text-black font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>
          Meet our dedicated team who are passionate about empowering entrepreneurs and guiding innovative startups to achieve their full potential in the business world.
        </p>
      </div>

      <div className='pl-8 pr-8 xl:pl-32 xl:pr-32 pt-8'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-1'>
          <div className='bg-custom-green pt-12 lg:pt-5 relative w-[90%]'>
            <Testimonial
              image={Mo}
              text={'"I started Opulence for the purpose of making a difference in my community, using social enterprise as the vehicle."'}
              whiteRectangle={whiteRectangle}
              greenRectangle={greenRectangle}
              name={'Mo Ali'}
              occupation={'Founder'}
              class1={'h-2 mt-[-9px] sm:mt-[-4px] xl:mt-[1px] sm:h-3 xl:h-4 max-w-xxxxxxs3 sm:max-w-xxxxxxs2 lg:max-w-xxxxxxs2 xl:max-w-xxxxxxs1'}
              slideFrom={'left'}
            />
          </div>

          <div className='bg-custom-yellow pt-12 lg:pt-5 relative w-[90%]'>
            <Testimonial
              image={Archie}
              text={'" I’m fully committed to supporting entrepreneurs at Opulence Growth and excited to navigate the world of enterprise with you, facing challenges and successes together!"'}
              whiteRectangle={whiteRectangle}
              greenRectangle={yellowRectangle}
              name={'Jazper Peacey'}
              occupation={'Enterprise Lead'}
              class1={'h-2 mt-[-9px] sm:mt-[-4px]  xl:mt-[1px]   sm:h-3 xl:h-4 max-w-xxxxxs sm:max-w-xxxxs2 lg:max-w-xxxxs3 xl:max-w-xxxxs'}
              slideFrom={'left'}
            />
          </div>
        </div>
      </div>

      <div className='pt-16 pr-12 mt-6'>
        <Contact />
      </div>
    </div>
  );
}

export default AboutUs;
