function Program({ image, name, greenRectangle, explanation, class1, class2, class3, class4 }) {
  return (
    <div className='flex justify-center'>
      <div className={`relative ${class4} h-full flex flex-col`}>
        <img src={image} alt="Program" className="w-full flex-shrink-0" />

        <div className={`relative bg-white rounded-tr-8xl w-[90%]  ${class2} p-4 -mt-16 mx-auto`}>
          <div className='relative'>
            <h1 className={`font-Inter ${class3} font-bold relative z-10`}>
              {name}
            </h1>
            <img
              className={`absolute ${class1} top-1/2 transform -translate-y-1/2`}
              src={greenRectangle}
              alt="Background"
            />
          </div>

          <p className='font-Inter text-[12px] sm:text-[16px] md:text-[16px] lg:text-[15px] xl:text-[19px] mt-4 pr-2 relative z-10'>
            {explanation}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Program;
