import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const AdminHeader = () => {
  const { isAdminLoggedIn, logout } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = () => {
    logout();
    navigate('/'); // Redirect to the homepage
  };

  return (
    <div className="bg-gray-800 text-white p-4 flex justify-between items-center">
      <h1 className="text-2xl font-bold">Opulence Growth</h1>
      {isAdminLoggedIn && (
        <button
          className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded"
          onClick={handleSignOut}
        >
          Sign Out
        </button>
      )}
    </div>
  );
};

export default AdminHeader;
