import Eventprogram1 from '../assets/Eventprogram1.png';
import Eventprogram2 from '../assets/Eventprogram2.png';

const eventData = [
  {
    date: '8th October 2024',
    heading: 'Monthly masterclass:',
    heading1: 'Creating a positive work environment',
    explanation: 'A positive work culture is essential for business success. Join Richard Roberts, with over 20 years of experience at companies like Virgin Mobile, to learn practical steps for building motivated, high-performing teams and fostering growth.',
    image: Eventprogram1
  },
  {
    date: '22nd October 2024',
    heading: 'Social/networking event:',
    heading1: 'Be the Boss',
    explanation: 'Our annual impact event. An exciting evening featuring entrepreneur showcases, networking, and presentations from select founders. Connect with ambitious individuals, explore innovative ideas, and enjoy refreshments in an inspiring, growth-focused atmosphere.',
    image: Eventprogram2
  }
];

export default eventData;
