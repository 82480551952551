import React, { useEffect, useRef } from 'react';
import backgroundImage from '../assets/HomeBackground.png';
import greenRectangle from '../assets/Rectangle19.png';
import Aja from '../assets/Aja.png';
import Patrick from '../assets/Patrick.png';
import whiteRectangle from '../assets/Rectangle7.png';
import Testimonial from '../components/Testimonial';
import Contact from '../pages/Contact';
import { Link } from 'react-router-dom';
import PageSubHeading from '../components/PageSubHeading';
import yellowRectangle from '../assets/Rectangle15.png';
import { FaArrowRight } from "react-icons/fa";

function Home() {
  const impactRef = useRef(null);

  useEffect(() => {
    const animateValue = (id, start, end, duration) => {
      if (start === end) return;
      let current = start;
      const increment = end > start ? 1 : -1;
      const stepTime = Math.abs(Math.floor(duration / (end - start)));
      const obj = document.getElementById(id);
      const timer = setInterval(() => {
        current += increment;
        obj.innerHTML = current;
        if (current === end) {
          clearInterval(timer);
        }
      }, stepTime);
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateValue("counter1", 0, 100, 2000);
          animateValue("counter2", 0, 40, 2000);
          animateValue("counter3", 0, 92, 2000);
          animateValue("counter4", 0, 500, 2000);
          animateValue("counter1-small", 0, 100, 2000);
          animateValue("counter2-small", 0, 40, 2000);
          animateValue("counter3-small", 0, 92, 2000);
          animateValue("counter4-small", 0, 500, 2000);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0.1,
    });

    if (impactRef.current) {
      observer.observe(impactRef.current);
    }

    return () => {
      if (impactRef.current) {
        observer.unobserve(impactRef.current);
      }
    };
  }, []);

  return (
    <div className='bg-bg-grey'>
      <div className='relative pb-8'>
        <img src={backgroundImage} alt="Background" className='w-full' />
        <p className='absolute bottom-11 md:bottom-28 sm:bottom-10 pl-10 lg:pl-20 pr-10 text-black font-Inter text-[16px] sm:text-[28px] md:text-[28px] lg:text-[32px] xl:text-[40px] font-bold text-shadow-xl'>
          Supporting entrepreneurs from underrepresented communities
        </p>
      </div>

      <div>
        <div>
          <PageSubHeading title="Introducing Opulence" />
          <p className='text-left pb-5 pl-10 lg:pl-32 pr-10 lg:pr-32 text-black font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>
            Opulence is dedicated to creating a vibrant, inclusive ecosystem where entrepreneurs from all walks of life can thrive. We focus on empowering individuals who aspire to build businesses that not only succeed but also contribute positively to society.
            <br /> <br />
            Based in Bristol, we offer a dynamic platform of support, mentorship, and resources tailored to the unique challenges faced by under-represented founders. At Opulence, we believe in nurturing both the person and the business, fostering a community of innovators ready to make a meaningful difference.
          </p>
        </div>
        <div ref={impactRef}>
          <PageSubHeading title="Our Impact" />
        </div>

        {/* Layout for larger screens */}
        <div className='hidden md:flex flex-col mb-12'>
          <div className='flex justify-center pl-2 md:pl-20 md:pr-20 lg:pl-32 lg:pr-32 pr-2 items-center'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-20 lg:gap-20 xl:gap-32'>
              <div className='relative flex flex-col'>
                <h1 className="font-Inter text-[42px] md:text-[60px] lg:text-[80px] xl:text-[96px] font-black text-custom-green text-center thick-text">
                  <span id="counter1">0</span><span className="text-black thick-text">%</span>
                </h1>
                <p className='font-Inter text-[12px] sm:text-[16px] lg:text-[18px] font-bold text-black text-center '>Free to all our entrepreneurs</p>
              </div>
              <div className='flex flex-col'>
                <h1 className='font-Inter text-[42px] md:text-[60px] lg:text-[80px] xl:text-[96px] font-black text-custom-green text-center thick-text'>
                  <span id="counter2">0</span><span className="text-black thick-text">+</span>
                </h1>
                <p className='font-Inter text-[12px] sm:text-[16px] lg:text-[18px] font-bold text-black text-center'>Entrepreneurs supported to develop & start a business in 2023 </p>
              </div>
              <div className='flex flex-col'>
                <h1 className='font-Inter text-[42px] md:text-[60px] lg:text-[80px] xl:text-[96px] font-black text-custom-green text-center thick-text' >
                  <span id="counter3">0</span><span className="text-black thick-text">%</span>
                </h1>
                <p className='font-Inter text-[12px] sm:text-[16px] lg:text-[18px] font-bold text-black text-center'>Participants come from underrepresented communities</p>
              </div>
            </div>
          </div>
          <div className='flex flex-col  mt-4 lg:mt-12'>
            <h1 className='mt-10 font-Inter text-[42px] md:text-[60px] lg:text-[80px] xl:text-[96px] font-black text-custom-green text-center thick-text'>
              £<span id="counter4">0</span>K<span className='text-black thick-text'>+</span>
            </h1>
            <p className='font-Inter text-[12px] sm:text-[16px] lg:text-[18px] font-bold text-black text-center'>Extra revenue achieved by entrepreneurs with our support</p>
          </div>
        </div>

        {/* Layout for small screens */}
        <div className='flex md:hidden flex-col mb-8'>
          <div className='flex justify-center pl-2 lg:pl-32 lg:pr-32 pr-2 items-center'>
            <div className='grid grid-cols-2 gap-4 md:gap-20 lg:gap-20 xl:gap-32'>
              <div className='relative flex flex-col'>
                <h1 className="mt-6 font-Inter text-[40px] md:text-[70px] lg:text-[80px] xl:text-[90px] font-black text-custom-green text-center thick-text">
                  <span id="counter4-small">0</span>K<span className="text-black thick-text">+</span>
                </h1>
                <p className='px-4 font-Inter text-[12px] sm:text-[16px] lg:text-[18px] font-bold text-black text-center'>
                  Extra revenue achieved by entrepreneurs with our support
                </p>
              </div>
              <div className='relative flex flex-col'>
                <h1 className="mt-6 font-Inter text-[40px] md:text-[70px] lg:text-[80px] xl:text-[90px] font-black text-custom-green text-center thick-text">
                  <span id="counter1-small">0</span><span className="text-black thick-text">%</span>
                </h1>
                <p className='px-4 font-Inter text-[12px] sm:text-[16px] lg:text-[18px] font-bold text-black text-center'>
                  Free to all our entrepreneurs
                </p>
              </div>
              <div className='flex flex-col'>
                <h1 className='mt-6 font-Inter text-[40px] md:text-[70px] lg:text-[80px] xl:text-[90px] font-black text-custom-green text-center thick-text'>
                  <span id="counter2-small">0</span><span className="text-black thick-text">+</span>
                </h1>
                <p className='px-4 font-Inter text-[12px] sm:text-[16px] lg:text-[18px] font-bold text-black text-center'>
                  Entrepreneurs supported to develop & start a business in 2023
                </p>
              </div>
              <div className='flex flex-col'>
                <h1 className='mt-6 font-Inter text-[40px] md:text-[70px] lg:text-[80px] xl:text-[90px] font-black text-custom-green text-center thick-text'>
                  <span id="counter3-small">0</span><span className="text-black thick-text">%</span>
                </h1>
                <p className='px-4 font-Inter text-[12px] sm:text-[16px] lg:text-[18px] font-bold text-black text-center'>
                  Participants come from underrepresented communities
                </p>
              </div>
            </div>
          </div>
        </div>

        <PageSubHeading
          title="A message from the Opulence Entrepreneurs"
          isShowArrow={false}
          className="text-[14px] sm:text-lg md:text-xl lg:text-2xl xl:text-3xl"
        />
        <div className='pl-8 pr-8 xl:pl-32 xl:pr-32 pt-8'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-1'>
            <div className='bg-custom-green pt-12 lg:pt-5  relative w-[90%]'>
              <Testimonial
                image={Aja}
                text={'"I didn’t really know anything about business until I joined the program, they gave me a space to go to that helped me open my shop."'}
                whiteRectangle={whiteRectangle}
                greenRectangle={greenRectangle}
                name={'Aja Darboe'}
                occupation={'CEO of PBA Studios'}
                class1={'h-2 mt-[-9px] sm:mt-[-4px]  xl:mt-[1px]   sm:h-3 xl:h-4   max-w-xxxxxxs1 sm:max-w-xxxxxs1  lg:max-w-xxxxs4 xl:max-w-xxxxs1'}
                slideFrom={'left'}
              />
            </div>

            <div className='bg-custom-yellow pt-12 lg:pt-5  relative w-[90%]'>
              <Testimonial
                image={Patrick}
                text={'"Its a big blessing, the program is very unique, wholesome and from the heart."'}
                whiteRectangle={whiteRectangle}
                greenRectangle={yellowRectangle}
                name={'Patrick “Patch” De-Salis'}
                occupation={'Founder of PushDat productions'}
                class1={'h-2 mt-[-9px] sm:mt-[-4px]  xl:mt-[1px]   sm:h-3 xl:h-4  max-w-xxxs1  sm:max-w-xxxs2.5 lg:max-w-xxs1 xl:max-w-xxs'}
                slideFrom={'left'}
              />
            </div>
          </div>
        </div>
        <div className='pt-24 flex justify-center'>
          <Link to='/flagship-program'>
            <p className='flex bg-green-500 items-center hover:bg-green-400 hover:text-black text-black bg-green-500 md:mt-8 pl-12 pr-12 md:pl-32 md:pr-32 pt-4 pb-4 text-[12px] sm:text-[18px] xl:text-[20px] font-bold font-Inter py-2 rounded-br-8xl transition-all duration-300 ease-in-out'>
              Learn more about the Flagship Program
              <FaArrowRight className='ml-4' />
            </p>
          </Link>
        </div>
        <div className='mt-8'>
          <PageSubHeading title="Thank You!" isShowArrow={false} />
        </div>
        <p className='text-left pt-4 pl-10 lg:pl-32 pr-10 lg:pr-32 text-black font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>
          On behalf of Opulence, Babbasa and our Partners, We would like to thank all of our entrepreneurs for their amazing hard work over the year. It's not just recognised by us but also the communities around us that have noticed the difference our entrepreneurs have made. Marvin Rees, the Mayor of Bristol 2022, has produced a personal video commending them all:
        </p>
        <div className="flex items-center justify-center pt-8 md:pt-12">
          {/* <video className='w-[70%] md:w-[70%]' controls>
            <source
              src="https://youtu.be/iDz-SvUFZ-o"
              type="video/mp4"

            />
            Your browser does not support the video tag.
          </video> */}
          <iframe
            className="
              sm:w-2/3          /* 66% width on small screens */
              md:w-1/2          /* 50% width on medium screens */
              lg:w-3/5          /* 40% width on large screens */
              h-64              /* 16rem height for small screens */
              sm:h-80           /* 20rem height for small screens */
              md:h-96           /* 24rem height for medium screens */
              lg:h-[500px]      /* Custom height on large screens */
            "
            src="https://www.youtube.com/embed/7-FoApVB784?si=io-mpFtrf7d9AbwX&modestbranding=1&iv_load_policy=3&controls=1"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Opulence Homepage Video"
          />

        </div>
      </div>

      <div className='pt-12 pr-12 '>
        <Contact />
      </div>
    </div>
  );
}

export default Home;