// firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database"; // Use 'firebase/database' for Realtime Database

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDlyRuBPPghfTPmsIZJJj0oozfyhWkyZKY",
  authDomain: "opulence-growth-website.firebaseapp.com",
  databaseURL: "https://opulence-growth-website-default-rtdb.firebaseio.com",
  projectId: "opulence-growth-website",
  storageBucket: "opulence-growth-website.appspot.com",
  messagingSenderId: "738743435635",
  appId: "1:738743435635:web:7a2648966a7b7b78be4506"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const db = getDatabase(app); // This should correctly initialize the Realtime Database

export { db };
