import { useState } from 'react';
import PageSubHeading from '../components/PageSubHeading';
import { postData } from '../firebaseUtils';
import emailjs from 'emailjs-com';

function Register() {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		addressLine1: '',
		postcode: '',
		ethnicBackground: '',
		gender: '',
		employmentStatus: '',
		businessIdea: '',
		aboutBusiness: ''
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value
		}));
	};

	const sendEmail = () => {
		// Get values from environment variables
		const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
		const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
		const templateID = process.env.REACT_APP_EMAILJS_REGISTER_TEMPLATE_ID;

		emailjs.send(serviceID, templateID, formData, publicKey)
			.then((response) => {
				console.log('Email sent successfully!', response.status, response.text);
			}, (error) => {
				console.error('Failed to send email:', error);
			});
	};

	const submitForm = async (e) => {
		e.preventDefault();
		try {
			const response = await postData('UserData', formData);
			if (response.success) {
				sendEmail();
				alert('Form submitted successfully!');
				setFormData({
					firstName: '',
					lastName: '',
					email: '',
					phoneNumber: '',
					addressLine1: '',
					postcode: '',
					ethnicBackground: '',
					gender: '',
					employmentStatus: '',
					businessIdea: '',
					aboutBusiness: ''
				});
			} else {
				alert('Error occurred during submission');
			}
		} catch (e) {
			console.error('Error submitting data: ', e);
			alert('Error occurred');
		}
	};

	return (
		<div className='relative bg-bg-grey'>
			<div className='relative pt-6'>
				<PageSubHeading title="Register" isShowArrow={false} />
			</div>
			<p className='relative text-left pt-4 pl-10 lg:pl-32 pr-10 lg:pr-32 text-black font-Inter text-[12px] lg:text-[20px]'>
				We support entrepreneurs from a diverse range of under-represented backgrounds with the aim of building socially beneficial businesses in Bristol.
			</p>
			<div className='pr-10 md:pr-20 pt-12'>
				<form onSubmit={submitForm} className='flex flex-col bg-white rounded-tr-12xl pt-10'>
					<div className='grid grid-cols-2 gap-x-4 gap-y-10 pt-4 pl-10 lg:pl-32 pr-10 lg:pr-32'>
						<label className='flex flex-col'>
							<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>First Name</span>
							<input type="text" name="firstName" minLength="3" maxLength="20" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.firstName} onChange={handleChange} />
						</label>
						<label className='flex flex-col'>
							<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Last Name</span>
							<input type="text" name="lastName" minLength="3" maxLength="20" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.lastName} onChange={handleChange} />
						</label>
						<label className='flex flex-col'>
							<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Email</span>
							<input type="email" name="email" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.email} onChange={handleChange} />
						</label>
						<label className='flex flex-col'>
							<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Phone number</span>
							<input type="text" name="phoneNumber" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.phoneNumber} onChange={handleChange} />
						</label>
						<label className='flex flex-col'>
							<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Address line 1</span>
							<input type="text" name="addressLine1" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.addressLine1} onChange={handleChange} />
						</label>
						<label className='flex flex-col'>
							<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Postcode</span>
							<input type="text" name="postcode" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.postcode} onChange={handleChange} />
						</label>
						<label className='flex flex-col'>
							<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Ethnic background</span>
							<input type="text" name="ethnicBackground" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.ethnicBackground} onChange={handleChange} />
						</label>
						<label className='flex flex-col'>
							<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Gender</span>
							<input type="text" name="gender" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.gender} onChange={handleChange} />
						</label>
						<label className='flex flex-col'>
							<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Employment status</span>
							<input type="text" name="employmentStatus" required className='mt-[22px] sm:mt-1  border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.employmentStatus} onChange={handleChange} />
						</label>
						<label className='flex flex-col'>
							<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Do you have a business idea?</span>
							<input type="text" name="businessIdea" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.businessIdea} onChange={handleChange} />
						</label>
					</div>
					<label className='flex flex-col pt-10 pl-10 lg:pl-32 pr-10 lg:pr-32'>
						<span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px] pb-4'>Tell us about your business</span>
						<textarea name="aboutBusiness" minLength="3" maxLength="200" required className='mt-1 border-2 border-black bg-transparent p-0 w-[90%] h-[100px] focus:outline-none' value={formData.aboutBusiness} onChange={handleChange} />
					</label>
					<div className='relative pb-10 pt-10 pl-10 lg:pl-32'>
						<button type="submit" className=' hover:bg-green-600 text-white bg-green-500 pr-20 pl-4 text-[18px] font-Inter py-2 rounded-tr-3xl'>
							Submit
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Register;
