import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaBars } from 'react-icons/fa';
import Logo from '../assets/Logo.png';

function Header() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setDropdownOpen(false);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className='flex items-center justify-between bg-white pt-4 pb-4 pr-4 lg:pr-20 pl-4 lg:pl-20 h-auto md:h-24 relative'>
      <Link to="/">
        <img src={Logo} alt="Logo" className='h-10 md:h-12 lg:h-14 xl:h-16' />
      </Link>
      <div className='md:hidden absolute top-4 right-4'>
        <button onClick={toggleMobileMenu} className='text-black'>
          <FaBars size={24} />
        </button>
      </div>
      {mobileMenuOpen && (
        <>
          <div className='fixed inset-0 bg-black opacity-50 z-20' onClick={closeMobileMenu}></div>
          <div className='fixed top-0 right-0 w-3/4 max-w-xs h-full bg-white z-30 p-4 flex flex-col'>
            <button onClick={closeMobileMenu} className='text-black mb-4'>
              <FaBars size={24} />
            </button>
            <nav className='flex flex-col space-y-2 flex-grow'>
              <Link to="/" className='text-black font-Inter hover:text-gray-400 p-2' onClick={closeMobileMenu}>Home</Link>
              <Link to="/events" className='text-black font-Inter hover:text-gray-400 p-2' onClick={closeMobileMenu}>Events</Link>
              <Link to="/about" className='text-black font-Inter hover:text-gray-400 p-2' onClick={closeMobileMenu}>About Us</Link>
              <div className='relative'>
                <button
                  onClick={toggleDropdown}
                  className='flex items-center font-Inter text-black hover:text-gray-400 p-2 w-full'>
                  Our Programs {dropdownOpen ? <FaChevronUp className='ml-3' /> : <FaChevronDown className='ml-3' />}
                </button>
                {dropdownOpen && (
                  <div className='mt-2 w-full bg-white border text-center justify-center rounded shadow-lg z-10 mb-4'>
                    <Link to="/up-program" onClick={closeMobileMenu}>
                      <button className='block py-2 text-black font-Inter text-[13px] hover:bg-gray-200 text-left w-full px-4'>
                        For 10-16 years old
                      </button>
                    </Link>
                    <Link to="/flagship-program" onClick={closeMobileMenu}>
                      <button className='block py-2 text-black font-Inter text-[13px] hover:bg-gray-200 text-left w-full px-4'>
                        For 16-30 years old
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </nav>
            <Link to='/register' className='mt-auto'>
              <button className='hover:bg-green-600 bg-custom-green border text-sm lg:text-[20px] text-black font-bold font-Inter py-1 lg:py-4 px-4 lg:px-8 rounded-md lg:rounded-[18px] hover:bg-custom-gray w-full'>
                Register
              </button>
            </Link>
          </div>
        </>
      )}
      <div className='hidden md:flex items-center md:space-x-10 md:flex-row'>
        <nav className='flex flex-col md:flex-row md:space-x-10 font-Inter text-[12px] md:text-[14px] lg:text-[20px]'>
          <Link to="/" className='text-black hover:text-gray-400 p-2'>Home</Link>
          <Link to="/events" className='text-black hover:text-gray-400 p-2'>Events</Link>
          <Link to="/about" className='text-black hover:text-gray-400 p-2'>About Us</Link>
          <div className='relative'>
            <button
              onClick={toggleDropdown}
              className='flex items-center text-black hover:text-gray-400 p-2'>
              Our Programs {dropdownOpen ? <FaChevronUp className='ml-3' /> : <FaChevronDown className='ml-3' />}
            </button>
            {dropdownOpen && (
              <div className='absolute mt-2 w-full bg-white border text-center justify-center rounded shadow-lg z-10'>
                <Link to="/up-program">
                  <button onClick={toggleDropdown} className='block py-2 text-black font-Inter text-[13px] lg:text-[15px] hover:bg-gray-200 text-left w-full px-4'>
                    For 10-16 years old
                  </button>
                </Link>
                <Link to="/flagship-program">
                  <button onClick={toggleDropdown} className='flex flex-end py-2 text-black font-Inter text-[13px] lg:text-[15px] hover:bg-gray-200 text-left w-full px-4'>
                    For 16-30 years old
                  </button>
                </Link>
              </div>
            )}
          </div>
        </nav>
        <Link to='/register' className='hidden md:block'>
          <button className='hover:bg-green-600 bg-custom-green border text-15 lg:text-[21px] text-black font-bold font-Inter py-1 lg:py-3 px-3 lg:px-8 rounded-md lg:rounded-[18px] hover:bg-custom-gray'>
            Register
          </button>
        </Link>
      </div>
    </header>
  );
}

export default Header;
