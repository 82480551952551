import backgroundImage from '../assets/EventsBackground.png';
import greenRectangle from '../assets/Rectangle19.png';
import { Link } from 'react-router-dom';
import EventProgram from '../components/EventProgram';
import PageSubHeading from '../components/PageSubHeading';
import eventData from '../components/eventData';
import { FaArrowRight } from "react-icons/fa";

function Events() {
  return (
    <div className='bg-bg-grey'>
      <div className='relative pb-12'>
        <img src={backgroundImage} alt="Background" className='w-full rounded-br-[100px] md:rounded-br-[200px]' />
        <p className='absolute bottom-20 md:bottom-36 pl-10 lg:pl-20 pr-10 text-black font-Inter text-[16px] sm:text-[28px] md:text-[28px] lg:text-[32px] xl:text-[40px] font-bold  text-shadow-xl'>
        Join a community of entrepreneurs ready to share, learn, and grow together 
        </p>
      </div>
      <div>
        <PageSubHeading title="Upcoming Events & Masterclasses" page="events" />
        <p className='text-left pl-10 lg:pl-14 pr-8 lg:pr-32 text-black font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>
          A collection of unforgettable experiences and invaluable lessons!
          <br></br>
          Discover our upcoming events featuring exciting workshops, inspiring talks, and engaging activities.
        </p>
      </div>
      <div className='grid grid-rows-2 sm:grid-rows-1 sm:grid-cols-2 pt-4 gap-x-6 gap-y-0 md:gap-x-8 lg:gap-x-12 pl-8 pr-8 lg:pl-14 lg:pr-14'>
        {eventData.map((event, index) => (
          <div key={index} className='pt-2'>
            <EventProgram
              image={event.image}
              date={event.date}
              greenRectangle={greenRectangle}
              heading={event.heading}
              heading1={event.heading1}
              explanation={event.explanation}
              class1={index === 0 ? 'h-3 sm:h-3 mt-2.5 sm:mt-3 lg:mt-3.5 max-w-xxs2 sm:max-w-xs5 lg:max-w-xs1 xl:max-w-ss1' : 'h-3 sm:h-3 mt-2.5 sm:mt-3 lg:mt-3.5 max-w-xxs sm:max-w-xs2 lg:max-w-ss2 xl:max-w-sm1.5'}
            />
          </div>
        ))}
      </div>
      <div className='pt-8 flex justify-center'>
        <p className='text-black pl-15 pr-15 md:pl-30 md:pr-30 text-[12px] md:text-[15px] lg:text-[18px] xl:text-[20px]font-Inter font-bold py-2 rounded-br-8xl'>
          Register to gain access to these events
        </p>
      </div>
      <div className='pt-8 pb-8 flex justify-center'>
        <Link to='/register'>
          <button className='flex bg-green-500 items-center hover:bg-green-400 hover:text-black text-black bg-green-500 pr-12 pl-12 font-bold text-[12px] sm:text-[18px]  xl:text-[20px] font-Inter py-2 rounded-tr-3xl text-center'>
            Register
            <FaArrowRight className='ml-4' />
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Events;
