import React, { useEffect, useRef } from 'react';

function Testimonial({ image, text, greenRectangle, name, occupation, class1, slideFrom }) {
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (slideFrom === 'left') {
            ref.current.classList.add('animate-slideInLeft');
          } else if (slideFrom === 'right') {
            ref.current.classList.add('animate-slideInRight');
          }
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [slideFrom]);

  return (
    <div
      ref={ref}
      className="relative flex items-center justify-between"
      style={{ width: '100%', height: '300px', position: 'relative', overflow: 'visible' }}
    >
      {/* Left Side - Centered Text */}
      <div className="flex items-center justify-center w-full h-full">
        <p className="font-Inter font-bold text-center mb-16 mr-6 ml-6 sm:mb-12 text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]">
          {text}
        </p>
      </div>

      {/* Right Side - Image and White Curved Rectangle */}
      <div className="relative flex items-end justify-end w-[80%] sm:w-1/2 h-full">
        {/* Image fixed at bottom-right */}
        <img
          src={image}
          alt="Person"
          className="absolute bottom-0 right-0 object-cover"
          style={{ width: 'auto', height: '100%' }}
        />

        {/* White Curved Rectangle */}
        <div
          className="absolute flex items-center justify-start p-4 bg-white
          w-[calc(200%)] sm:w-[calc(280%)] left-[-10%] sm:left-[-50%] sm:h-[140px]
          h-[110px] rounded-tr-[50px]
          transform translate-x-[-50%] translate-y-[50%]"
        >
          <div className="absolute bg-white rounded-tr-12xl w-[80%] h-24 p-4 grid grid-rows-3">
            <h1 className="font-Inter text-[11px] sm:text-[15px] lg:text-[17px] xl:text-[18.5px] z-10 font-bold">
              {name}
            </h1>
            <img
              className={class1}
              src={greenRectangle}
              alt="Background"
            />
            <h1 className="font-Inter text-[10px] sm:text-[14px] lg:text-[16px] xl:text-[18px] font-bold">
              {occupation}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
