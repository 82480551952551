import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import backgroundImage from '../assets/UpProgrammeBackground.png';
import greenRectangle from '../assets/Rectangle19.png';
import UpProgram1 from '../assets/UpProgram1.png';
import UpProgram2 from '../assets/UpProgram2.png';
import UpProgram3 from '../assets/UpProgram3.png';
import Contact from '../pages/Contact';
import Program from '../components/Program';
import PageSubHeading from '../components/PageSubHeading';

function UpProgramme() {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Duration of the animation
      once: false,    // Whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div className='bg-bg-grey'>
      <div className='relative pb-10'>
        <img src={backgroundImage} alt="Background" className='w-full' />
        <p className='absolute bottom-16 md:bottom-28 pl-10 lg:pl-20 pr-10 text-black font-Inter text-[16px] sm:text-[28px] md:text-[28px] lg:text-[32px] xl:text-[40px] font-bold text-shadow-xl'>
          Supporting the development of your child with sports, careers, and life skills!
        </p>
      </div>
      <div>
        <PageSubHeading title="What is the UP Program?" isShowArrow={false}/>
      </div>
      <p className='text-left pt-4 pl-10 lg:pl-32 pr-10 lg:pr-32 text-black font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>
        The UP (Unlimited Potential) Program offers dynamic workshops designed for young people aged 10-16, focusing on sports, wellbeing, business,
        and career paths. The program provides an engaging and supportive environment where participants can explore their interests and develop essential skills.
        Through a blend of physical activities, personal development sessions, and career guidance, UP aims to inspire and empower young individuals to reach their full potential.
      </p>
      <div className='pt-6'>
        <PageSubHeading title="Our Core Support Systems are:" isShowArrow={false}/>
      </div>
      <div className='grid grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 pt-4 gap-x-6 gap-y-0 md:gap-x-8 lg:gap-x-12 pl-8 pr-8 lg:pl-14 lg:pr-14'>
        <div className='pt-4 md:pt-8' data-aos="fade-up">
          <Program
            image={UpProgram1}
            greenRectangle={greenRectangle}
            name={'Sports & Wellbeing Support'}
            explanation={'Starting with football, basketball & non-contact rugby'}
            class1={'h-2 lg:h-2.5 top-1/4 lg:top-1/2 mt-3.5 lg:mt-2.5 max-w-xxs lg:max-w-xxs xl:max-w-xs2'}
            class2={'h-auto sm:h-auto lg:h-auto xl:h-auto'}
            class3={'sm:text-[16px] lg:text-[16px] xl:text-[20px]'}
            class4={'w-full'}
          />
        </div>
        <div className='pt-8' data-aos="fade-up">
          <Program
            image={UpProgram2}
            greenRectangle={greenRectangle}
            name={'Business and Careers'}
            explanation={'Exploring career paths including entrepreneurship'}
            class1={'h-2 lg:h-2.5 top-1/4 lg:top-1/2 mt-3.5 lg:mt-2.5 max-w-xxxs lg:max-w-xxxs xl:max-w-xxs1'}
            class2={'h-auto sm:h-auto lg:h-auto xl:h-auto'}
            class3={'sm:text-[16px] lg:text-[16px] xl:text-[20px]'}
            class4={'w-full'}
          />
        </div>
        <div className='pt-8' data-aos="fade-up">
          <Program
            image={UpProgram3}
            greenRectangle={greenRectangle}
            name={'Homework Club'}
            explanation={'Assisting your child with their educational needs'}
            class1={'h-2 lg:h-2.5 top-1/4 lg:top-1/2 mt-3.5 lg:mt-2.5 max-w-xxxxs2 lg:max-w-xxxxs3 xl:max-w-xxxs1'}
            class2={'h-auto sm:h-auto lg:h-auto xl:h-auto'}
            class3={'sm:text-[16px] lg:text-[16px] xl:text-[20px]'}
            class4={'w-full'}
          />
        </div>
      </div>
      <div className='pt-12 pr-12'>
        <Contact />
      </div>
    </div>
  )
}

export default UpProgramme;
