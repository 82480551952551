import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import backgroundImage from '../assets/FlagshipBackground.png';
import greenRectangle from '../assets/Rectangle19.png';
import Program1 from '../assets/Program1.png';
import Program2 from '../assets/Program2.png';
import Program3 from '../assets/Program3.png';
import Program4 from '../assets/Program4.png';
import Program5 from '../assets/Program5.png';
import Program6 from '../assets/Program6.png';
import Contact from '../pages/Contact';
import Program from '../components/Program';
import { Link } from 'react-router-dom';
import PageSubHeading from '../components/PageSubHeading';
import OpulenceVideo from '../assets/home_page_video.mp4';
import { FaArrowRight } from "react-icons/fa";

function FlagshipProgram() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false,
    });
  }, []);

  return (
    <div className='bg-bg-grey'>
      <div className='relative pb-8'>
        <img src={backgroundImage} alt="Background" className='w-full rounded-br-[100px] md:rounded-br-[200px]' />
        <p className='absolute bottom-16 md:bottom-28 pl-10 lg:pl-20 pr-10 text-black font-inter text-[16px] sm:text-[28px] md:text-[28px] lg:text-[32px] xl:text-[40px] font-bold text-shadow-xl'>
          Apply now for FREE start up business support in Bristol
        </p>
      </div>
      <div>
        <PageSubHeading title="What is the Flagship Program?" isShowArrow={false} />
        <span className='z-10 font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px] pl-6 lg:pl-32 mb-4'>
          Watch a glimpse of what we do:
        </span>
        <div className="flex items-center justify-center pt-8 md:pt-12">
          <video className='w-[80%] md:w-[70%]' controls>
            <source src={OpulenceVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className='pt-6'>
        <PageSubHeading title="Our Core Support Systems are:" isShowArrow={false} />
      </div>
      <div className='grid grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 gap-x-6 gap-y-0 md:gap-x-8 pt-4 lg:gap-x-12 pl-3 pr-8 lg:pl-14 lg:pr-14'>
        <div className='pt-4 md:pt-8' data-aos="fade-up">
          <Program
            image={Program1}
            greenRectangle={greenRectangle}
            name={'1:1 Business Support'}
            explanation={'1:1’s with our business coordinators to explore ideas and strategy. With a direct focus on the real-life challenges that you are facing and how we can help you overcome these obstacles.'}
            class1={'h-2 lg:h-2.5 top-1/4 lg:top-1/2 mt-3.5 lg:mt-2.5 max-w-xxxs0 sm:max-w-xxxs0 md:max-w-xxxs0 xl:max-w-xxs1'}
            class2={'h-auto lg:h-auto'}
            class3={'sm:text-[16px] lg:text-[16px] xl:text-[20px] w-60'}
            class4={'w-[85%]'}
          />
        </div>
        <div className='pt-8' data-aos="fade-up">
          <Program
            image={Program2}
            greenRectangle={greenRectangle}
            name={'Free Desk Space'}
            explanation={'Work from our creative office or from our partner locations across Bristol. Providing you with the space to bring your best ideas to flourish.'}
            class1={'h-2 lg:h-2.5 top-1/4 lg:top-1/2 mt-3.5 lg:mt-2.5 max-w-xxxxs3 md:max-w-xxxxs2 xl:max-w-xxxs1'}
            class2={'h-auto lg:h-auto'}
            class3={'sm:text-[16px] lg:text-[16px] xl:text-[20px] w-52'}
            class4={'w-[85%]'}
          />
        </div>
        <Link to='/events'>
          <div className='pt-8' data-aos="fade-up">
            <Program
              image={Program3}
              greenRectangle={greenRectangle}
              name={'Social Networking Events'}
              explanation={'Join our monthly networking events to connect with like-minded entrepreneurs at similar stages of their business journey. Share ideas, successes, and challenges in a supportive, growth-focused environment.'}
              class1={'h-2 lg:h-2.5 top-1/4 lg:top-1/2 mt-3.5 lg:mt-2.5 max-w-xxs1 md:max-w-xxs2.5 xl:max-w-xs4'}
              class2={'h-auto lg:h-auto'}
              class3={'sm:text-[16px] lg:text-[16px] xl:text-[20px] w-80 hover:bg-gray-100'}
              class4={'w-[85%]'}
            />
          </div>
        </Link>
        <Link to='/events'>
          <div className='pt-8' data-aos="fade-up">
            <Program
              image={Program4}
              greenRectangle={greenRectangle}
              name={'Expert Masterclasses'}
              explanation={"Interactive masterclasses with experts in their fields. All topics are chosen by you, designed to meet your specific needs!"}
              class1={'h-2 lg:h-2.5 top-1/4 lg:top-1/2 mt-3.5 lg:mt-2.5 max-w-xxxs md:max-w-xxxs0 xl:max-w-xxs1'}
              class2={'h-auto lg:h-auto'}
              class3={'sm:text-[16px] lg:text-[16px] xl:text-[20px] w-80 hover:bg-gray-100'}
              class4={'w-[85%]'}
            />
          </div>
        </Link>
        <div className='pt-8' data-aos="fade-up">
          <Program
            image={Program5}
            greenRectangle={greenRectangle}
            name={'Mentoring'}
            explanation={'Find a mentor with your specific requirements to help guide you through your journey. With access to over 300 established experts and professional across a wide variety of industries.'}
            class1={'h-2 lg:h-2.5 top-1/4 lg:top-1/2 mt-3.5 lg:mt-2.5 max-w-xxxxxs1 md:max-w-xxxxxs xl:max-w-xxxxs1'}
            class2={'h-36 lg:h-64'}
            class3={'sm:text-[16px] lg:text-[16px] xl:text-[20px] w-80'}
            class4={'w-[85%]'}
          />
        </div>
        <div className='pt-8' data-aos="fade-up">
          <Program
            image={Program6}
            greenRectangle={greenRectangle}
            name={'Opulence Community'}
            explanation={'Being an entrepreneur is often a lonely journey! So we connect you with local like-minded entrepreneurs with Opulence community group chat and meetings.'}
            class1={'h-2 lg:h-2.5 top-1/4 lg:top-1/2 mt-3.5 lg:mt-2.5 max-w-xxxs md:max-w-xxxs xl:max-w-xxs1'}
            class2={'h-auto lg:h-auto'}
            class3={'sm:text-[16px] lg:text-[16px] xl:text-[20px] w-60'}
            class4={'w-[85%]'}
          />
        </div>
      </div>
      <div className='pt-8 flex justify-center'>
        <p className='text-black pl-10 pr-8 md:pl-40 md:pr-40 pb-4 text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px] font-Inter py-2 rounded-br-8xl'>
          Our Enterprise Program is completely <span className="font-bold">FREE</span> to the entrepreneurs we support! This is because of our primary partner <a href="https://babbasa.com/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Babbasa</a> and core funders we work with.
        </p>

      </div>
      <div className='pt-7 flex justify-center'>
        <Link to='/register'>
          <button className='flex bg-green-500 items-center hover:bg-green-400 hover:text-black text-black bg-green-500 pr-12 pl-12 font-bold text-[12px] lg:text-[18px] xl:text-[20px] font-Inter py-2 rounded-tr-3xl text-center'>
            Register
            <FaArrowRight className='ml-4' />
          </button>
        </Link>
      </div>
      <div className='pt-12 pr-12'>
        <Contact />
      </div>
    </div>
  )
}

export default FlagshipProgram;
