import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { ref, onValue } from "firebase/database";
import { CSVLink } from 'react-csv';
import { Tooltip } from 'react-tooltip';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { CiExport } from "react-icons/ci";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('getInTouch');
  const [getInTouchData, setGetInTouchData] = useState([]);
  const [registerData, setRegisterData] = useState([]);
  const { isAdminLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdminLoggedIn) {
      navigate('/admin_login'); // Redirect to login if not logged in
    }
  }, [isAdminLoggedIn, navigate]);

  // Set up a real-time listener to update data whenever there's a change in the UserData path
  useEffect(() => {
    const dbRef = ref(db, 'UserData');
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const getInTouch = [];
        const register = [];

        let getInTouchId = 1;
        let registerId = 1;

        Object.keys(data).forEach((key) => {
          const entry = { ...data[key] }; // Create a shallow copy of the entry
          // Determine if entry is for Get In Touch or Register table based on available fields
          if (entry.message) {
            getInTouch.push({ id: getInTouchId++, ...entry });
          } else if (entry.aboutBusiness) {
            register.push({ id: registerId++, ...entry });
          }
        });

        setGetInTouchData(getInTouch);
        setRegisterData(register);
      } else {
        console.log("No data available");
      }
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  const renderTable = (data, type) => {
    if (data.length === 0) {
      return (
        <div className="flex justify-center items-center h-[60vh]">
          <p className="text-2xl text-gray-500">No data available!</p>
        </div>
      );
    }

    return (
      <div className="overflow-auto max-h-[80vh]">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-2 px-4 border">ID</th> {/* ID column */}
              {type === 'getInTouch' ? (
                <>
                  <th className="py-2 px-4 border">First Name</th>
                  <th className="py-2 px-4 border">Last Name</th>
                  <th className="py-2 px-4 border">Email</th>
                  <th className="py-2 px-4 border">Business</th>
                  <th className="py-2 px-4 border">Message</th>
                </>
              ) : (
                <>
                  <th className="py-2 px-4 border">First Name</th>
                  <th className="py-2 px-4 border">Last Name</th>
                  <th className="py-2 px-4 border">Email</th>
                  <th className="py-2 px-4 border">Phone</th>
                  <th className="py-2 px-4 border">Address</th>
                  <th className="py-2 px-4 border">Postcode</th>
                  <th className="py-2 px-4 border">Ethnic Background</th>
                  <th className="py-2 px-4 border">Gender</th>
                  <th className="py-2 px-4 border">Employment Status</th>
                  <th className="py-2 px-4 border">Business Idea</th>
                  <th className="py-2 px-4 border">About Business</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="py-2 px-4 border text-center">{item.id}</td> {/* Display ID */}
                {type === 'getInTouch' ? (
                  <>
                    <td className="py-2 px-4 border text-center">{item.firstName}</td>
                    <td className="py-2 px-4 border text-center">{item.lastName}</td>
                    <td className="py-2 px-4 border text-center">{item.email}</td>
                    <td className="py-2 px-4 border text-center">{item.business}</td>
                    <td className="py-2 px-4 border text-center">
                      <span data-tooltip-id={`tooltip-${type}-${index}`} data-tooltip-content={item.message}>
                        {item.message?.split(' ').slice(0, 5).join(' ')}...
                      </span>
                    </td>
                  </>
                ) : (
                  <>
                    <td className="py-2 px-4 border text-center">{item.firstName}</td>
                    <td className="py-2 px-4 border text-center">{item.lastName}</td>
                    <td className="py-2 px-4 border text-center">{item.email}</td>
                    <td className="py-2 px-4 border text-center">{item.phoneNumber}</td>
                    <td className="py-2 px-4 border text-center">{item.addressLine1}</td>
                    <td className="py-2 px-4 border text-center">{item.postcode}</td>
                    <td className="py-2 px-4 border text-center">{item.ethnicBackground}</td>
                    <td className="py-2 px-4 border text-center">{item.gender}</td>
                    <td className="py-2 px-4 border text-center">{item.employmentStatus}</td>
                    <td className="py-2 px-4 border text-center">{item.businessIdea}</td>
                    <td className="py-2 px-4 border text-center">
                      <span data-tooltip-id={`tooltip-${type}-${index}`} data-tooltip-content={item.aboutBusiness}>
                        {item.aboutBusiness?.split(' ').slice(0, 5).join(' ')}...
                      </span>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {data.map((item, index) => (
          <Tooltip
            id={`tooltip-${type}-${index}`}
            key={`tooltip-${type}-${index}`}
            className="bg-black text-white p-2 rounded-md shadow-md max-w-xs text-left whitespace-normal break-words"
          />
        ))}
      </div>
    );
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-8">Admin Dashboard</h1>
      <div className="flex justify-between mb-8">
        <div className="flex space-x-4">
          <button
            className={`w-full whitespace-nowrap bg-custom-green border text-14 font-Inter py-2 px-4 rounded ${activeTab === 'getInTouch' ? 'bg-green-600 text-white' : 'bg-gray-200 text-black'}`}
            onClick={() => setActiveTab('getInTouch')}
          >
            Get In Touch Form Data
          </button>
          <button
            className={`w-full whitespace-nowrap bg-custom-green border text-14 font-Inter py-2 px-4 rounded ${activeTab === 'register' ? 'bg-green-600 text-white' : 'bg-gray-200 text-black'}`}
            onClick={() => setActiveTab('register')}
          >
            Register Form Data
          </button>
        </div>
        <CSVLink
          data={activeTab === 'getInTouch' ? getInTouchData : registerData}
          filename={`${activeTab}-data.csv`}
          className="flex items-center bg-green-500 text-white py-2 px-4 rounded space-x-2"
        >
          <CiExport className="w-5 h-5" />
          <span>Export to CSV</span>
        </CSVLink>
      </div>
      {activeTab === 'getInTouch' && renderTable(getInTouchData, 'getInTouch')}
      {activeTab === 'register' && renderTable(registerData, 'register')}
    </div>
  );
};

export default Dashboard;
