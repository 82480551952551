import { useState } from 'react';
import PageSubHeading from '../components/PageSubHeading';
import { postData } from '../firebaseUtils';
import emailjs from 'emailjs-com';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    business: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const sendEmail = () => {
		// Get values from environment variables
		const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
		const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
		const templateID = process.env.REACT_APP_EMAILJS_GET_IN_TOUCH_TEMPLATE_ID;

		emailjs.send(serviceID, templateID, formData, publicKey)
			.then((response) => {
				console.log('Email sent successfully!', response.status, response.text);
			}, (error) => {
				console.error('Failed to send email:', error);
			});
	};

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      const response = await postData('UserData', formData);
      if (response.success) {
        sendEmail();
        alert('Form submitted successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          business: '',
          message: ''
        });
      } else {
        alert('Error occurred during submission');
      }
    } catch (e) {
      console.error('Error submitting data: ', e);
      alert('Error occurred');
    }
  };

  return (
    <div className='relative'>
      <div className='relative pt-4'>
        <PageSubHeading title="Get In Touch" isShowArrow={false} />
      </div>
      <p className='relative text-left pt-4 pl-10 lg:pl-32 pr-10 lg:pr-32 text-black font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>
        We look forward to connecting with you. Our team will respond as soon as possible.
      </p>
      <div className='pr-10 md:pr-20 pt-12'>
        <form onSubmit={submitForm} className='flex flex-col bg-white rounded-tr-12xl pt-10'>
          <div className='grid grid-cols-2 gap-x-4 gap-y-10 pt-4 pl-10 lg:pl-32 pr-10 lg:pr-32'>
            <label className='flex flex-col'>
              <span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>First Name</span>
              <input type="text" name="firstName" minLength="3" maxLength="20" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.firstName} onChange={handleChange} />
            </label>
            <label className='flex flex-col'>
              <span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Last Name</span>
              <input type="text" name="lastName" minLength="3" maxLength="20" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.lastName} onChange={handleChange} />
            </label>
            <label className='flex flex-col'>
              <span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Email</span>
              <input type="email" name="email" required className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[100px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.email} onChange={handleChange} />
            </label>
            <label className='flex flex-col'>
              <span className='text-custom-getInTouch font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>Business (Optional)</span>
              <input type="text" name="business" className='mt-1 border-b-2 border-custom-getInTouch bg-transparent p-0 w-[120px] sm:w-[200px] lg:w-[400px] focus:outline-none' value={formData.business} onChange={handleChange} />
            </label>
          </div>
          <label className='flex flex-col pt-10 pl-10 lg:pl-32 pr-10 lg:pr-32'>
            <span className='text-custom-getInTouch font-Intertext-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px] pb-4'>Your message</span>
            <textarea name="message" minLength="3" maxLength="200" required className='mt-1 border-2 border-black bg-transparent p-0 w-[90%] h-[100px] focus:outline-none' value={formData.message} onChange={handleChange} />
          </label>
          <div className='relative pb-10 pt-10 pl-10 lg:pl-32'>
            <button type="submit" className=' hover:bg-green-600 text-white bg-green-500 pr-20 pl-4 text-[18px] font-Inter py-2 rounded-tr-3xl'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
