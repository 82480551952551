// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { ref, child, get } from "firebase/database";
import { useAuth } from '../AuthContext';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const dbRef = ref(db);
      const snapshot = await get(child(dbRef, `admins/super_admin`));

      if (snapshot.exists()) {
        const adminData = snapshot.val();

        // Compare input username and password with the admin data
        if (adminData.username === username && adminData.password === password) {
          login();
          navigate('/admin_dashboard');
        } else {
          alert('Invalid credentials!');
        }
      } else {
        alert('No admin data found!');
      }
    } catch (error) {
      console.error('Error fetching admin data:', error);
      alert('Error fetching admin data. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Admin Login</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Username</label>
            <input
              type="text"
              className="mt-1 p-2 w-full border border-gray-300 rounded"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              className="mt-1 p-2 w-full border border-gray-300 rounded"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
            className='w-full hover:bg-green-600 bg-custom-green border text-12 lg:text-[18px] text-white font-Inter  py-2 rounded hover:bg-custom-gray'
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
