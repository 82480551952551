import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import logo from '../assets/white-logo.png';
import { AiOutlineInstagram, AiOutlineYoutube, AiFillLinkedin } from "react-icons/ai";

function Footer() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className='bg-black pb-10 h-auto'>
      <div className='flex justify-between'>
        <div className='pl-4 md:pl-12 pt-16'>
          <Link to="/">
            <img className='pb-6 w-auto h-[80px] lg:h-28' src={logo} alt="Logo" />
          </Link>
          <a href="mailto:hello@opulencegrowth.co.uk" className="pl-4 pt-4 text-white font-Inter text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px] underline">
            Hello@OpulenceGrowth.co.uk
          </a>
          <div className='flex justify-between pl-4 pt-20 md:pt-40 lg:pt-28 space-x-4'>
            <AiOutlineYoutube className='text-white' size={48} />

            <a href="https://www.linkedin.com/company/opulence-growth/" target="_blank" rel="noopener noreferrer">
              <AiFillLinkedin className='text-white pl-1' size={48} />
            </a>

            <a href="https://www.instagram.com/opulencegrowth/" target="_blank" rel="noopener noreferrer">
              <AiOutlineInstagram className='text-white' size={48} />
            </a>
          </div>

        </div>
        <div className='pr-6 md:pr-12 text-right'>
          <ul className='text-white font-Inter font-semi-bold text-[12px] sm:text-[16px] lg:text-[18px] xl:text-[20px]'>
            <li className='pt-16'>
              <Link className='hover:bg-gray-700' to='/'>Home</Link>
            </li>
            <li className='pt-4'>
              <Link className='hover:bg-gray-700' to='/flagship-program'>Flagship Program</Link>
            </li>
            <li className='pt-4'>
              <Link className='hover:bg-gray-700' to='/up-program'>UP Program</Link>
            </li>
            <li className='pt-4'>
              <Link className='hover:bg-gray-700' to='/about'>About Us</Link>
            </li>
            <li className='pt-4'>
              <Link className='hover:bg-gray-700' to='/events'>Events</Link>
            </li>
            <li className='pt-4'>
              <Link className='hover:bg-gray-700' to='/contact'>Contact</Link>
            </li>
            <li className='pt-4'>
              <Link className='hover:bg-gray-700' to='/register'>Register</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
