import { db } from './firebaseConfig';
import { ref, set, push } from "firebase/database";

// Function to post data
export const postData = async (path, data) => {
  try {
    const newDataRef = push(ref(db, path)); // Creates a new unique key in the specified path
    await set(newDataRef, data);
    return { success: true };
  } catch (error) {
    console.error("Error posting data:", error);
    return { success: false, error };
  }
};
