// App.js
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import FlagshipProgram from './pages/FlagshipProgram';
import UpProgramme from './pages/UpProgramme';
import Register from './pages/Register';
import Events from './pages/Events';
import Footer from './components/Footer';
import Header from './components/Header';
import AdminHeader from './components/AdminHeader';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';

function App() {
  const location = useLocation();

  const isAdminPage = location.pathname === '/admin_login' || location.pathname === '/admin_dashboard';

  return (
    <div>
      {/* Render different headers based on the route */}
      {isAdminPage ? <AdminHeader /> : <Header />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin_login" element={<AdminLogin />} />
        <Route path="/admin_dashboard" element={<AdminDashboard />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/flagship-program" element={<FlagshipProgram />} />
        <Route path="/up-program" element={<UpProgramme />} />
        <Route path="/register" element={<Register />} />
        <Route path="/events" element={<Events />} />
      </Routes>

      {/* Only show Footer on non-admin pages */}
      {!isAdminPage && <Footer />}
    </div>
  );
}

export default App;
